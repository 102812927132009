// import React, { useEffect, useState } from "react";
// import styled from "styled-components";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faChevronDown,
//   faChevronUp,
//   faCircle,
// } from "@fortawesome/free-solid-svg-icons";
// import { useParams } from "react-router-dom";

// // Container for the entire course outline
// const CourseContainer = styled.div`
//   padding: 20px;
//   max-width: 90%;
//   margin: 5% 5% 0%;
//   background: #ffffff;
//   border-radius: 10px;
//   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
//   height: 80vh; /* Set height to 90% of viewport */
//   overflow-y: auto; /* Make content scrollable vertically */

//   @media (max-width: 768px) {
//     width: 100%;
//     padding: 15px;
//     height: 80vh; /* Adjust height for smaller screens if needed */
//   }
// `;

// // Title of the course
// const CourseTitle = styled.h2`
//   font-weight: bold;
//   color: #3a3a8f;
// `;

// // Button container for Home and New Course buttons
// const ButtonContainer = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   gap: 10px;
//   margin-bottom: 20px;
// `;

// // Button styling for Home and New Course buttons
// const StyledButton = styled.button`
//   background-color: #3a3a8f;
//   color: white;
//   border: none;
//   padding: 8px 16px;
//   border-radius: 4px;
//   font-weight: bold;
//   cursor: pointer;
//   &:hover {
//     background-color: #2b2b6d;
//   }
// `;

// // Each module title (e.g., "Module 1: Basics")
// const ModuleTitle = styled.div`
//   font-weight: bold;
//   font-size: 1em;
//   background-color: #d3d3d3;
//   padding: 10px;
//   border-radius: 20px;
//   margin-bottom: 5px;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   color: #3a3a8f;
//   justify-content: space-between;
// `;

// // Arrow icon for expanding/collapsing
// const Arrow = styled(FontAwesomeIcon)`
//   transition: transform 0.3s;
//   transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0deg)")};
//   font-size: 1.2em;
//   color: #3a3a8f;
// `;

// // Each lesson title (e.g., "Lesson 1: Introduction")
// const LessonTitle = styled.div`
//   background-color: #e9ecef;
//   padding: 10px;
//   border-radius: 5px;
//   margin-bottom: 5px;
//   cursor: pointer;
//   color: #343a40;
//   display: flex;
//   align-items: center;
//   font-size: 0.9em;
//   justify-content: space-between;
//   margin-left: 20px; /* Indent lessons */
// `;

// // Container for each lesson's content
// const LessonContent = styled.div`
//   padding: 0px;
//   background-color: #f8f9fa;
//   border-radius: 5px;
//   margin-left: 40px; /* Indent content further */
// `;

// // Collapsible content container for each lesson's content item
// const CollapsibleContent = styled.div`
//   padding: 10px;
//   background-color: #f1f1f1;
//   border-radius: 5px;
//   margin-top: 10px;
//   display: ${({ isOpen }) => (isOpen ? "block" : "none")};
// `;

// const VideoGrid = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   gap: 20px;
//   margin-top: 10px;
// `;

// const VideoWrapper = styled.div`
//   position: relative;
//   padding-bottom: 20.25%; /* 16:9 aspect ratio */
//   height: 0;
//   overflow: hidden;
//   flex: 1 1 calc(33.333% - 20px); /* 3 videos per row */
//   background: #000;
//   border-radius: 8px;
//   margin-top: 10px;

//   iframe {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     border: none;
//   }
// `;

// const CourseOutline = ({ courseOutline }) => {
//   console.log(courseOutline, "=====courseOutline-----");
//   const [openModuleIndex, setOpenModuleIndex] = useState(null);
//   const [openLessonIndex, setOpenLessonIndex] = useState({});
//   const [openContentIndex, setOpenContentIndex] = useState(
//     courseOutline &&
//       courseOutline?.modules?.reduce((acc, module, moduleIndex) => {
//         module.lessons.forEach((_, lessonIndex) => {
//           acc[`${moduleIndex}-${lessonIndex}-0`] = true;
//         });
//         return acc;
//       }, {})
//   );

//   const toggleModule = (moduleIndex) => {
//     setOpenModuleIndex(openModuleIndex === moduleIndex ? null : moduleIndex);
//   };

//   const toggleLesson = (moduleIndex, lessonIndex) => {
//     console.log(moduleIndex, lessonIndex);
//     setOpenLessonIndex((prev) => ({
//       ...prev,
//       [`${moduleIndex}-${lessonIndex}`]: !prev[`${moduleIndex}-${lessonIndex}`],
//     }));
//   };

//   const toggleContent = (moduleIndex, lessonIndex, contentIndex) => {
//     setOpenContentIndex((prev) => ({
//       ...prev,
//       [`${moduleIndex}-${lessonIndex}-${contentIndex}`]:
//         !prev[`${moduleIndex}-${lessonIndex}-${contentIndex}`],
//     }));
//   };
//   const getYoutubeEmbedUrl = (url) => {
//     const videoId = url.split("v=")[1];
//     return `https://www.youtube.com/embed/${videoId}`;
//   };

//   return (
//     <CourseContainer>
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "space-between",
//           marginBottom: "10px",
//         }}
//       >
//         <CourseTitle className="ml-2">{courseOutline?.title || ""}</CourseTitle>
//       </div>
//       <hr className="hr-dot"></hr>

//       {courseOutline?.modules?.map((module, moduleIndex) => (
//         <div key={moduleIndex} className="rounded mb-4">
//           <div className="rounded flex-grow-1">
//             <ModuleTitle onClick={() => toggleModule(moduleIndex)}>
//               <div>
//                 <span className="me-2 mr-2 ml-3" style={{ color: "#2a2a72" }}>
//                   <FontAwesomeIcon icon={faCircle} size="xs" />{" "}
//                 </span>
//                 {`Chapter ${module.moduleNumber}: ${module.moduleTitle}`}
//               </div>

//               <Arrow
//                 isOpen={openModuleIndex === moduleIndex}
//                 icon={
//                   openModuleIndex === moduleIndex ? faChevronUp : faChevronDown
//                 }
//               />
//             </ModuleTitle>
//           </div>

//           {openModuleIndex === moduleIndex && (
//             <div style={{ width: "98%" }} className="p-2">
//               {module.lessons.map((lesson, lessonIndex) => (
//                 <div key={lessonIndex} className="rounded mb-3">
//                   <LessonTitle
//                     onClick={() => toggleLesson(moduleIndex, lessonIndex)}
//                   >
//                     {`Lesson ${lesson.lessonNumber}: ${lesson.lessonTitle}`}
//                     <Arrow
//                       isOpen={openLessonIndex[`${moduleIndex}-${lessonIndex}`]}
//                       icon={
//                         openLessonIndex[`${moduleIndex}-${lessonIndex}`]
//                           ? faChevronUp
//                           : faChevronDown
//                       }
//                     />
//                   </LessonTitle>

//                   {openLessonIndex[`${moduleIndex}-${lessonIndex}`] && (
//                     <div style={{ width: "98%" }}>
//                       <LessonContent>
//                         <p>
//                           <b>Description: </b>
//                           {lesson.description}
//                         </p>
//                         {lesson.content ? (
//                           lesson.content.map((contentItem, contentIndex) => (
//                             <div
//                               key={contentIndex}
//                               className="p-2 mb-2 border rounded"
//                               style={{
//                                 backgroundColor: "#dee1e4",
//                               }}
//                             >
//                               <div
//                                 className="content-item d-flex justify-content-between align-items-center"
//                                 onClick={() =>
//                                   toggleContent(
//                                     moduleIndex,
//                                     lessonIndex,
//                                     contentIndex
//                                   )
//                                 }
//                               >
//                                 <b>{`${lesson.lessonNumber}.${
//                                   contentIndex + 1
//                                 } ${contentItem.titleName}`}</b>
//                                 <Arrow
//                                   isOpen={
//                                     openContentIndex &&
//                                     openContentIndex[
//                                       `${moduleIndex}-${lessonIndex}-${contentIndex}`
//                                     ]
//                                   }
//                                   icon={
//                                     openContentIndex &&
//                                     openContentIndex[
//                                       `${moduleIndex}-${lessonIndex}-${contentIndex}`
//                                     ]
//                                       ? faChevronUp
//                                       : faChevronDown
//                                   }
//                                 />
//                               </div>
//                               <CollapsibleContent
//                                 isOpen={
//                                   openContentIndex &&
//                                   openContentIndex[
//                                     `${moduleIndex}-${lessonIndex}-${contentIndex}`
//                                   ]
//                                 }
//                               >
//                                 <p>{contentItem.titleDescription}</p>
//                                 {contentItem.youtubeSearchTerms && (
//                                   <>
//                                     <p>
//                                       <strong>Youtube Search Terms:</strong>
//                                     </p>
//                                     <ul>
//                                       {contentItem.youtubeSearchTerms.map(
//                                         (term, termIndex) => (
//                                           <li
//                                             key={termIndex}
//                                             style={{
//                                               padding: "4px",
//                                               marginBottom: "10px",
//                                             }}
//                                           >
//                                             {term}
//                                           </li>
//                                         )
//                                       )}
//                                     </ul>
//                                   </>
//                                 )}

//                                 {contentItem.youtubeLinks ? (
//                                   <>
//                                     <p>
//                                       <strong>Relevant YouTube Videos:</strong>
//                                     </p>
//                                     <VideoGrid>
//                                       {contentItem.youtubeLinks.map(
//                                         (linkGroup, i) => (
//                                           <VideoWrapper key={i}>
//                                             <iframe
//                                               src={linkGroup[0].URL}
//                                               title={linkGroup[0].title}
//                                               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//                                               allowFullScreen
//                                             ></iframe>
//                                           </VideoWrapper>
//                                         )
//                                       )}
//                                     </VideoGrid>
//                                   </>
//                                 ) : (
//                                   <></>
//                                 )}
//                               </CollapsibleContent>
//                             </div>
//                           ))
//                         ) : (
//                           <>No Content Found</>
//                         )}
//                       </LessonContent>
//                     </div>
//                   )}
//                 </div>
//               ))}
//             </div>
//           )}
//         </div>
//       ))}
//     </CourseContainer>
//   );
// };

// export default CourseOutline;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowCircleLeft,
    faChevronDown,
    faChevronUp,
    faCircle,
} from "@fortawesome/free-solid-svg-icons";

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

// Styled components
const LayoutContainer = styled.div`
  display: flex;
  max-width: 95%;
  margin: 1% 0% 0% 3%;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  height: 80vh;
  //   scrollbar-width: none;
  overflow-y: auto;
  margin-bottom: 2%;
`;
const CourseTitle = styled.h2`
  background-color: #2a2a72;
  color: white;
  margin: 3% auto;
  font-size: 1.8em; /* Increase font size for emphasis */
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: 3px solid #3a3a8f;
  padding-bottom: 10px;
  margin-bottom: 20px;
`;
const Sidebar = styled.div`
  width: 30%;
  height: 100%;
  overflow: auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-right: 1px solid #ddd;
`;

const MainContent = styled.div`
  height: 100%;
  overflow: auto;
  width: 70%;
  padding: 20px;
`;

const ModuleTitle = styled.div`
  font-weight: bold;
  font-size: 1em;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #3a3a8f;
  background-color: ${({ isActive }) => (isActive ? "#2a2a72" : "#d3d3d3")};
  color: ${({ isActive }) => (isActive ? "#ffffff" : "#3a3a8f")};
  border: ${({ isActive }) => (isActive ? "2px solid #3a3a8f" : "none")};
  transition: background-color 0.3s, color 0.3s;
`;

const Arrow = styled(FontAwesomeIcon)`
  transition: transform 0.3s;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0deg)")};
  font-size: 1.2em;
  color: #3a3a8f;
`;

const LessonTitle = styled.div`
  font-weight: bold;
  padding: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  color: #3a3a8f;
  background-color: #dee1e4;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
`;

const LessonContent = styled.div`
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
  margin-top: 10px;
`;

const ContentHeader = styled.h5`
  font-size: 1em;
  color: #3a3a8f;
  margin-top: 15px;
  font-weight: bold;
`;

const ContentDescription = styled.p`
  font-size: 0.9em;
  color: #555;
  margin-top: 5px;
`;

const VideoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
`;

const VideoWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const CourseOutline = ({ courseOutline, courseStage }) => {
    console.log("courseoutline-------------", courseOutline);
    const [selectedModuleIndex, setSelectedModuleIndex] = useState(null);
    const [expandedLessons, setExpandedLessons] = useState({});
    // const [courseOutline, setCourseOutline] = useState(courseOutlineData);

    const handleModuleClick = (index) => {
        if (index === selectedModuleIndex) {
            setSelectedModuleIndex(null);
            setExpandedLessons({});
        } else {
            setSelectedModuleIndex(index);
            const newExpandedLessons = {};
            courseOutline.modules[index].lessons.forEach((_, lessonIndex) => {
                newExpandedLessons[`${index}-${lessonIndex}`] = true;
            });
            setExpandedLessons(newExpandedLessons);
        }
    };

    const handleLessonClick = (moduleIndex, lessonIndex) => {
        setExpandedLessons((prev) => ({
            ...prev,
            [`${moduleIndex}-${lessonIndex}`]: !prev[`${moduleIndex}-${lessonIndex}`],
        }));
    };

    return (
        <>
            {courseOutline && (
                <>
                    <CourseTitle className="m-0 shadow-lg p-3">
                        {courseOutline?.title || ""}
                    </CourseTitle>
                    {courseOutline?.modules && (
                        <LayoutContainer className="mt-5">
                            <Sidebar className="rounded shadow-sm">
                                <div className="d-flex mb-2">
                                    <h2>Modules</h2>
                                </div>

                                {courseOutline?.modules?.map((module, index) => (
                                    <ModuleTitle
                                        key={index}
                                        isActive={selectedModuleIndex === index}
                                        onClick={() => handleModuleClick(index)}
                                        className="mb-3"
                                    >
                                        <div>{`Chapter ${module.moduleNumber}: ${module.moduleTitle}`}</div>
                                    </ModuleTitle>
                                ))}
                            </Sidebar>

                            <MainContent>
                                {selectedModuleIndex !== null && (
                                    <>
                                        <h3>
                                            {courseOutline.modules[selectedModuleIndex].moduleTitle}
                                        </h3>
                                        {courseOutline.modules[selectedModuleIndex].lessons.map(
                                            (lesson, lessonIndex) => (
                                                <div key={lessonIndex}>
                                                    <LessonTitle
                                                        onClick={() =>
                                                            handleLessonClick(
                                                                selectedModuleIndex,
                                                                lessonIndex
                                                            )
                                                        }
                                                    >
                                                        {`Lesson ${lesson.lessonNumber}: ${lesson.lessonTitle}`}
                                                        <Arrow
                                                            isOpen={
                                                                expandedLessons[
                                                                `${selectedModuleIndex}-${lessonIndex}`
                                                                ]
                                                            }
                                                            icon={
                                                                expandedLessons[
                                                                    `${selectedModuleIndex}-${lessonIndex}`
                                                                ]
                                                                    ? faChevronUp
                                                                    : faChevronDown
                                                            }
                                                        />
                                                    </LessonTitle>
                                                    {expandedLessons[
                                                        `${selectedModuleIndex}-${lessonIndex}`
                                                    ] && (
                                                            <LessonContent>
                                                                <p>
                                                                    <div className="mb-2">
                                                                        <strong>Overview:</strong>{" "}
                                                                        {lesson.description}
                                                                    </div>
                                                                    <strong>Course Description: </strong>
                                                                    {lesson.detailcontent ? (
                                                                        <>
                                                                            <div className="mt-2">
                                                                                <pre
                                                                                    style={{
                                                                                        textWrap: "auto",
                                                                                        fontFamily: "inherit",
                                                                                    }}
                                                                                >
                                                                                    {lesson?.detailcontent}
                                                                                </pre>
                                                                            </div>
                                                                        </>
                                                                    ) : <Skeleton count={10} />}
                                                                </p>
                                                                {lesson.content &&
                                                                    lesson.content.map(
                                                                        (contentItem, contentIndex) => (
                                                                            <div key={contentIndex}>
                                                                                <ContentHeader>
                                                                                    {`${selectedModuleIndex + 1}.${lessonIndex + 1
                                                                                        }.${contentIndex + 1} ${contentItem.titleName
                                                                                        }`}
                                                                                </ContentHeader>
                                                                                <ContentDescription>
                                                                                    {contentItem.titleDescription}
                                                                                </ContentDescription>
                                                                                <p>
                                                                                    <strong>YouTube Search Terms:</strong>
                                                                                </p>
                                                                                <ul>
                                                                                    {contentItem.youtubeSearchTerms ?
                                                                                        contentItem?.youtubeSearchTerms.map(
                                                                                            (term, termIndex) => (
                                                                                                <li key={termIndex}>{term}</li>
                                                                                            )
                                                                                        ) : <Skeleton count={4} />}
                                                                                </ul>
                                                                                {contentItem.YoutubeLinks ? (
                                                                                    <VideoGrid>
                                                                                        {contentItem.YoutubeLinks.map(
                                                                                            (linkGroup, i) => (
                                                                                                <VideoWrapper key={i}>
                                                                                                    <iframe
                                                                                                        src={linkGroup[0].URL}
                                                                                                        title={linkGroup[0].title}
                                                                                                        className="rounded shadow-sm"
                                                                                                        style={{ border: "none" }}
                                                                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                                                    ></iframe>
                                                                                                </VideoWrapper>
                                                                                            )
                                                                                        )}
                                                                                    </VideoGrid>
                                                                                ) : <Skeleton count={5} />}
                                                                            </div>
                                                                        )
                                                                    )}
                                                            </LessonContent>
                                                        )}
                                                </div>
                                            )
                                        )}
                                    </>
                                )}
                            </MainContent>
                        </LayoutContainer>
                    )}
                </>
            )}
        </>
    );
};

export default CourseOutline;
