import React from "react";
import { useNavigate } from "react-router-dom";

const CourseList = ({ results }) => {
    const navigate = useNavigate();
    const handleClick = (id) => {
        navigate(`/coursedata/${id}`);
    };
    return (
        <div id="image_grid" className="container-fluid m-3 mt-5">
            {results.length ? (
                results?.map((article, i) => (
                    <>
                        <div id="article_div" className="shadow bg-white rounded" key={i}>
                            <div onClick={() => handleClick(article.uuid)}>
                                <div className="p-5" title={article.courseTitle}>
                                    <div>
                                        <div id="article_headline">
                                            <h5>
                                                {article.courseTitle
                                                    ? article.courseTitle.charAt(0).toUpperCase() +
                                                    article.courseTitle.slice(1, 50) +
                                                    (article.courseTitle.length > 50 ? "..." : "")
                                                    : ""}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ))
            ) : (
                <p className="m-5 text-center">No Courses Found</p>
            )}
        </div>
    );
};

export default CourseList;
